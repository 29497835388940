import React from 'react'
import { Dialog } from '@headlessui/react'
import { XIcon } from '@heroicons/react/solid'
import { useAppDispatch, useAppSelector } from '../../../hooks/home'
import { toggleVitalsSlider } from '../../../store/slices/sliderSlice'
import { Button } from '../../buttons/button'
import { useFormik } from 'formik'
import {
  vitalsInitialState,
  vitalsvalidationSchema,
} from '../../../functionality/vitals'
import {
  addVitalTest,
  removeVitalTest,
  updateDocData,
} from '../../../store/slices/prescriptionSlice'
import Input from '../../../Comp/Input'
import FormErrorMessage from '../shared/FormErrorMessage'
import { PlusIcon, TrashIcon } from '@heroicons/react/outline';
import SocketService from '../../../services/socketService'


const VitalsSlider = () => {
  const socket = SocketService.socket;
  const dispatch = useAppDispatch()
  const prescription = useAppSelector(
    (state) => state.prescription.selectedPrescription
  )
  const tests_list = useAppSelector(state => state.prescription.tests_list)


  const toggle = () => {
    dispatch(toggleVitalsSlider(null))
  }

  const formik = useFormik({
    initialValues: vitalsInitialState,
    validationSchema: vitalsvalidationSchema,
    onSubmit: async (values) => {
      console.log('values', values)
      await dispatch(updateDocData('vitals', values))
      toggle()
    },
  })

  return (
    <Dialog.Panel className="pointer-events-auto h-full w-screen max-w-lg">
      <div className="flex h-full flex-col justify-center overflow-y-scroll bg-gray-100 py-6 shadow-xl">
        {/* <SliderHeader onClose={() => {}} title={undefined} /> */}
        <div className="mt-6 flex flex-col px-4 sm:px-6">
          <div className="flex items-center justify-between">
            <span className="text-2xl font-semibold">Vitals:</span>
            <span className="cursor-pointer rounded-full bg-green-400 p-1 text-white">
              <XIcon onClick={toggle} className="h-3 w-3" />
            </span>
          </div>
          <div className="mt-3 grid grid-cols-3 gap-2">
            <div>
              <Input
                name="temperature"
                value={formik.values.temperature}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                className="rounded-md border p-1 pl-2 text-sm"
                placeholder="Temperature"
              />
              <FormErrorMessage
                touched={formik.touched.temperature}
                error={formik.errors.temperature}
              />
            </div>
            <div>
              <Input
                name="bp"
                value={formik.values.bp}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                className="rounded-md border p-1 pl-2 text-sm"
                placeholder="BP"
              />
              <FormErrorMessage
                touched={formik.touched.bp}
                error={formik.errors.bp}
              />
            </div>
            <div>
              {' '}
              <Input
                name="prbpm"
                value={formik.values.prbpm}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                className="rounded-md border p-1 pl-2 text-sm"
                placeholder="PRBPM"
              />
              <FormErrorMessage
                touched={formik.touched.prbpm}
                error={formik.errors.prbpm}
              />
            </div>
            <div>
              <Input
                name="blood_glucose"
                value={formik.values.blood_glucose}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                className="rounded-md border p-1 pl-2 text-sm"
                placeholder="Blood Glucose"
              />
              <FormErrorMessage
                touched={formik.touched.blood_glucose}
                error={formik.errors.blood_glucose}
              />
            </div>
            <div>
              <Input
                name="respiratory_rate"
                value={formik.values.respiratory_rate}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                className="rounded-md border p-1 pl-2 text-sm"
                placeholder="Respiratory Rate"
              />
              <FormErrorMessage
                touched={formik.touched.respiratory_rate}
                error={formik.errors.respiratory_rate}
              />
            </div>
            <div>
              <Input
                name="spo2"
                value={formik.values.spo2}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                className="rounded-md border p-1 pl-2 text-sm"
                placeholder="%Spo2"
              />
              <FormErrorMessage
                touched={formik.touched.spo2}
                error={formik.errors.spo2}
              />
            </div>
            <div>
              <Input
                name="hemoglobin"
                value={formik.values.hemoglobin}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                className="rounded-md border p-1 pl-2 text-sm"
                placeholder="Hemoglobin"
              />
              <FormErrorMessage
                touched={formik.touched.hemoglobin}
                error={formik.errors.hemoglobin}
              />
            </div>
          </div>
          <Button
            disabled={formik.isSubmitting}
            onClick={formik.handleSubmit}
            className="mx-auto mt-6 w-40 text-lg"
          >
            {formik.isSubmitting ? 'Saving...' : 'Save'}
          </Button>
          {prescription.productCode === "SCC"
            &&
            <div className='mt-2'>
              Tests:
              <div className='flex space-y-2 flex-col'>

                {tests_list.map((test: any, i: number) => (
                  <div key={i} className="flex items-center">
                    <img className='h-10 w-10 mr-2 rounded' src={test.images?.[0]} />
                    <p>{test.name}</p>
                    <p className='ml-auto mr-5'>{test.pricePerUnit}/-</p>
                    {
                      prescription.productCode === "SCC"
                        &&
                        !!prescription.addOns.find(t => t.productId === test._id && t.freeAddOn)
                        ? <span className='font-semibold'>FREE</span>
                        : prescription.addOns.every(p => p.productId !== test._id)
                          ? <button className='p-1 rounded bg-green-600' onClick={() => {
                            socket.emit("add-vital-test", {
                              roomId: prescription._id,
                              productCode: test.productCode
                            })
                            dispatch(addVitalTest(test._id))
                          }}>
                            <PlusIcon className='h-3 w-3 text-white' />
                          </button>
                          : <button className='p-1 rounded bg-red-500' onClick={() => {
                            socket.emit("remove-vital-test", {
                              roomId: prescription._id,
                              productCode: test.productCode
                            })
                            dispatch(removeVitalTest(test._id))
                          }}>
                            <TrashIcon className='h-3 w-3 text-white' />
                          </button>
                    }
                  </div>
                ))}
              </div>
            </div>}
        </div>

      </div>
    </Dialog.Panel>
  )
}

export default VitalsSlider
